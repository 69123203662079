import {get} from "@/services/api";

/**
 * Get blog articles from api
 * @return Axios
 */
function getAll() {
    return get('blog/');
}

/**
 * Get article from api
 * @param {int} id - article id
 * @return Axios
 */
function getArticle(id) {
    return get(`blog/${id}`, null);
}

export const blogService = {
    getAll,
    getArticle
};