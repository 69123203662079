import {get} from "@/services/api";

/**
 * Get all programs from api
 *
 * @return Axios
 */
function getAll() {
    return get('study_programmes/');
}

/**
 * Get all programs with just base data
 *
 * @return Axios
 */
function getAllSimple() {
    return get('study_programmes/menu');
}

/**
 * Get program from api by id
 *
 * @param {int} id - program id
 * @return Axios
 */
function getById(id) {
    return get(`study_programmes/${id}`, null);
}

/**
 * Get programs for logged user
 *
 * @return Axios
 */
function getForUser() {
    return get(`study_programmes/user`, null);
}

export const studyProgramsService = {
    getAll,
    getAllSimple,
    getById,
    getForUser,
};