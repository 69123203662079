import {post} from "@/services/api";

/**
 * Make api call to send contact form
 * @param {string} subject - firstname
 * @param {string} body - lastname
 * @return {json} - return response from api
 */
async function sendContactForm(subject, body) {
    return post('contact-form', {
        subject,
        body,
    })
}

/**
 * Make api call to send consult form
 * @param {string} subject - firstname
 * @param {string} body - lastname
 * @param {string} email - lastname
 * @return {json} - return response from api
 */
async function sendConsultForm(subject, body, email) {
    return post('consult-form', {
        subject,
        body,
        email,
    })
}

export const generalService = {
    sendContactForm,
    sendConsultForm,
};