import {get, post} from "@/services/api";


/**
 * Get trainings from api
 * @return Axios
 */
function getAllUpcoming(page, training_type = 0, ordering = 0) {
    let params;
  
    params = {
        params: {
            page
        }
    }

    if (training_type > 0) {
        params['params']['training_type'] = training_type;
    }

    if (ordering > 0) {
        params['params']['ordering'] = ordering;
    }

    const upcomingGet = get('trainings', {}, params);
    return upcomingGet;
}

/**
 * Get trainings from api
 * @return Axios
 */
function getAllUpcomingAdmin(page, training_type = 0, ordering = 0) {
    let params;
  
    params = {
        params: {
            page
        }
    }

    if (training_type > 0) {
        params['params']['training_type'] = training_type;
    }

    if (ordering > 0) {
        params['params']['ordering'] = ordering;
    }

    const upcomingGet = get('trainings/admin', {}, params);
    return upcomingGet;
}

function getAllPast(page) {
    let params;
  
    params = {
        params: {
            page
        }
    }

    return get('trainings/past', {}, params);
}

function getTraining(id) {
    const trainingData = get(`trainings/${id}`, null);
    return trainingData;
}

/**
 * Make api call to user login
 * @param {number} training_id - training id
 * @param {string} firstname - firstname
 * @param {string} lastname - lastname
 * @param {string} email - email
 * @param {string} phone - phone
 * @param {boolean} signup 
 * @return {json} - return response from api
 */
async function trainingEnrollmentData(training_id, firstname, lastname, email, phone, signup, comment, comment_up, motivation, highest_edu, field, language_edu, mpsv_url, origin=1, interest_state=7, mpsv_code, reg_number_npo, mpsv_number, candidates_address) {
    return post('trainings/enroll', {
        training_id,
        firstname,
        lastname,
        email,
        phone,
        signup,
        comment,
        comment_up,
        motivation,
        highest_edu,
        field,
        language_edu,
        mpsv_url,
        origin,
        interest_state,
        mpsv_code,
        reg_number_npo, 
        mpsv_number, 
        candidates_address
    }, false)
}


/**
 * Make api call to user login
 * @param {number} training_id - training id
 * @param {string} firstname - firstname
 * @param {string} lastname - lastname
 * @param {string} email - email
 * @param {string} phone - phone
 * @param {boolean} signup 
 * @return {json} - return response from api
 */
async function trainingEnrollmentDataAdmin(training_id, firstname, lastname, email, phone, signup, comment, comment_up, motivation, highest_edu, field, language_edu, mpsv_url, origin, interest_state, mpsv_code, reg_number_npo, mpsv_number, candidates_address) {
    return post('trainings/enroll-from-admin', {
        training_id,
        firstname,
        lastname,
        email,
        phone,
        signup,
        comment,
        comment_up,
        motivation,
        highest_edu,
        field,
        language_edu,
        mpsv_url,
        origin,
        interest_state,
        mpsv_code, 
        reg_number_npo, 
        mpsv_number, 
        candidates_address
    }, false)
}


export const lectureService = {
    getAllUpcoming,
    getAllUpcomingAdmin,
    getAllPast,
    getTraining,
    trainingEnrollmentData,
    trainingEnrollmentDataAdmin
};