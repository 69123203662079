import {isApiResponseValid} from "@/helpers/helpers";
import {post, put} from "@/services/api";
import {AppError} from "@/services/errors";
import store from "@/store";

/**
 * Make api call to user login
 * @param {string} username - username
 * @param {string} password - password
 */
async function login(username, password) {
    const params = new URLSearchParams();
    params.append('username', username);
    params.append('password', password);

    // api call
    const response = await post('token', params, {}, false)

    if (!isApiResponseValid(response) || !response.data.access_token) {
        throw AppError;
    }

    // save to localStorage
    setAxiosToken(response.data.access_token)

    // get user data
    await store.dispatch('user/getUser', false)
}

/**
 * Make api call to get new password email
 * @param {string} email - email
 * @return {json|boolean} - return true on success or json with errors
 */
async function forgotPassword(email) {
    await put('forgot-password', {email}, false)
}

/**
 * Make api call to change password
 * @param {string} token - token
 * @param {string} new_password - new password
 * @return {json|boolean} - return true on success or json with errors
 */
async function resetPassword(token, new_password) {
    await post('reset-password', {token, new_password}, false)
}

/**
 * Set axios authorization header
 * @param {string} token - access token
 */
function setAxiosToken(token) {
    localStorage.setItem('token', token)
}

/**
 * Get auth token from local storage
 * @return {string} - access token
 */
function getAxiosToken() {
    return localStorage.getItem("token");
}

/**
 * Remove token from local storage - user logout and reload page or redirect to login page
 */
function logout() {
    localStorage.removeItem('token')
    if (['index', '404', 'about', 'privacyPolicy', 'personalData'].includes(location.page)) {
        location.reload();
    } else {
        location.href = '/login'
    }
}

export const authenticationService = {
    getAxiosToken,
    forgotPassword,
    resetPassword,
    login,
    logout,
};