import {get, patch, remove, post} from "@/services/api";
import axios from 'axios';
import {authenticationService} from "@/services/authentication";

/**
 * Get courses from api
 * @param {int|null} filter_difficulty - filter by difficulty [1-3]
 * @return Axios
 */
function getAll(filter_difficulty = 0) {
    let params;

    if (filter_difficulty > 0) {
        params = {
            params: {
                filter_difficulty
            }
        }
    }

    return get('courses/', {}, params);
}

/**
 * Get courses from api
 * @return Axios
 */
function getAllAdmin() {
    return get('courses/admin', {});
}

/**
 * Get course from api
 * @param {int} id - course id
 * @return Axios
 */
function getCourse(id) {
    return get(`courses/${id}`, null);
}

async function courseEnrollementData(id_order, academy_id, url, name, description, description_short, image, difficulty, language, is_active=true) {
    return post('courses/create-from-admin', {
        id_order,
        academy_id,
        url,
        name,
        description, 
        description_short, 
        image,
        difficulty,
        language,
        is_active
    }, false)
}


async function lectureEnrollementData(name, description, description_short, type=1, language=5, duration_seconds=0, course_id, section_id, is_active, id_order) {
    return post('courses/lecture', {
        name, 
        description, 
        description_short, 
        type, 
        language, 
        duration_seconds, 
        course_id, 
        section_id, 
        is_active, 
        id_order
    }, false)
}

async function sectionCreateData(course_id, name, id_order=10) {
    return post(`courses/section`, {
        name, 
        id_order,
        course_id
    }, false)
}

function uploadVideo(formData, onUploadProgress) {
    return axios.post(`/api/videos/lecture/${formData.get('lectureId')}`, formData, {
      headers: {
        'Authorization': `Bearer ${authenticationService.getAxiosToken()}`,
        'Content-Type': 'multipart/form-data',
      },
      // Přidej onUploadProgress do konfigurace
      onUploadProgress: (progressEvent) => {
        const percentCompleted = Math.round((progressEvent.loaded * 100) / progressEvent.total);
        if (onUploadProgress) {
          onUploadProgress(percentCompleted);
        }
      }
    });
  }

const uploadCourseImage = (formData) => {
    return post(`courses/image/${formData.get('courseId')}`, formData, {
      headers: {
        'Content-Type': 'multipart/form-data'
      }
    });
  };



async function updateCourse(id, id_order, academy_id, url, name, description, description_short, image, difficulty, language, is_active) {
    let data = {
        id,
        id_order,
        academy_id,
        url,
        name,
        description,
        description_short,
        image,
        difficulty,
        language,
        is_active
    }
    
    return patch(`courses/${id}`, data, false)
}


async function updateCourseSection(id, name, id_order, course_id) {
    let data = {
        id,
        name,
        id_order,
        course_id
    }
    
    return patch(`courses/section/${id}`, data, false)
}


async function updateLecture(id, name, id_order, description, description_short, is_active) {
    let data = {
        id,
        name,
        id_order,
        description,
        description_short,
        is_active
    }
    
    return patch(`courses/lecture/${id}`, data, false)
}

/**
 * Get all users from api
 * @return {list} - user data
 */
async function getCourseSections(courseId) {
    try {
        const response = await get(`courses/${courseId}/sections/admin`);

        return response;
    } catch (err) {
        if (err instanceof AppError && err.data) {
            store.dispatch('app/addFlashMessage', err.data)
        } else {
            throw err;
        }
    }
}

/**
 * Get all users from api
 * @return {list} - user data
 */
async function getCourseSectionLectures(sectionId) {
    try {
        const response = await get(`courses/lectures/${sectionId}/admin`);
        return response;
    } catch (err) {
        if (err instanceof AppError && err.data) {
            store.dispatch('app/addFlashMessage', err.data)
        } else {
            throw err;
        }
    }
}

async function deleteCourse(id) {
    let data = {
        id       
    }

    return remove(`courses/${id}`, data, false)
}


async function deleteCourseSection(id) {
    let data = {
        id       
    }

    return remove(`courses/section/${id}`, data, false)
}


async function deleteLecture(id) {
    let data = {
        id       
    }

    return remove(`courses/lecture/${id}`, data, false)
}


export const coursesService = {
    getAll,
    getAllAdmin,
    getCourse,
    courseEnrollementData,
    lectureEnrollementData,
    sectionCreateData,
    updateCourse,
    updateCourseSection,
    updateLecture,
    uploadVideo,
    uploadCourseImage,
    getCourseSections,
    getCourseSectionLectures,
    deleteCourse,
    deleteCourseSection,
    deleteLecture
};