import {authenticationService} from "@/services/authentication";
import * as errors from "@/services/errors";
import axios from "axios";

const apiUrl = "/api/";

/**
 * Make get request
 * @param {string} url - endPoint
 * @param {object|null} data - data
 * @param {object|null} params - params
 * @param {boolean} secured - use and check security headers
 * @return {axios} - axios data
 */
function get(url, data = null, params = null, secured = true) {
    return request('get', url, secured, data, params)
}

/**
 * Make post request
 * @param {string} url - endPoint
 * @param {object|null} data - data
 * @param {object|null} params - params
 * @param {boolean} secured - use and check security headers
 * @return {axios} - axios data
 */
function post(url, data = null, params = null, secured = true) {
    return request('post', url, secured, data, params)
}

/**
 * Make put request
 * @param {string} url - endPoint
 * @param {object|null} data - data
 * @param {object|null} params - params
 * @param {boolean} secured - use and check security headers
 * @return {axios} - axios data
 */
function put(url, data = null, params = null, secured = true) {
    return request('put', url, secured, data, params)
}

/**
 * Make patch request
 * @param {string} url - endPoint
 * @param {object|null} data - data
 * @param {object|null} params - params
 * @param {boolean} secured - use and check security headers
 * @return {axios} - axios data
 */
function patch(url, data = null, params = null, secured = true) {
    return request('patch', url, secured, data, params)
}

/**
 * Make delete request
 * @param {string} url - endPoint
 * @param {object|null} data - data
 * @param {object|null} params - params
 * @param {boolean} secured - use and check security headers
 * @return {axios} - axios data
 */
function remove(url, data = null, params = null, secured = true) {
    return request('delete', url, secured, data, params)
}

/**
 * Make request request
 * @param {string} method - method
 * @param {string} url - endPoint
 * @param {boolean} secured - use and check security headers
 * @param {object|null} data - data
 * @param {object|null} params - params
 * @return {axios} - axios data
 */
function request(method, url, secured, data = null, params = null) {
    let config;

    if (secured) {
        config = {
            headers: {
                Authorization: `Bearer ${authenticationService.getAxiosToken()}`
            },
        }
    }

    return axios({
        method,
        url: apiUrl + url,
        data,
        ...params,
        ...config
    }).catch(err => {
        // client received an error response (5xx, 4xx)
        if (err.response) {
            if (err.response.status === 400) {
                throw new errors.BadRequestError(err.response.message, err.response.data.detail ? err.response.data.detail: null)
            } else if (err.response.status === 401) {
                if (secured) {
                    authenticationService.logout();
                } else {
                    throw new errors.UnauthorizedError(err.response.message, err.response.data.detail ? err.response.data.detail: null)
                }
            } else if (err.response.status === 403) {
                if (secured) {
                    authenticationService.logout();
                } else {
                    throw new errors.ForbiddenError(err.response.message, err.response.data.detail ? err.response.data.detail: null)
                }
            } else if (err.response.status === 404) {
                throw new errors.NotFoundError(err.response.message)
            } else if (err.response.status === 422) {
                throw new errors.ValidationError(err.response.message, err.response.data.detail ? err.response.data.detail: null)
            } else if (err.response.status === 500) {
                throw new errors.InternalServerError(err.response.message)
            } else {
                // throw Error(err.response.data)
                throw Error(err.response.message)
            }
        } else if (err.request) {
            // client never received a response, or request never left
            throw Error(err.request.message)
        } else {
            // anything else
            throw Error(err.message)
        }
    })
}

export {
    get,
    post,
    put,
    patch,
    remove,
}