import {get, post} from "@/services/api";

/**
 * Get assessments from api
 *
 * @return Axios
 */
function getAll() {
    return get('assessments/', null);
}

/**
 * Get assessments from api
 *
 * @param {int} id - assessment id
 * @return Axios
 */
function getById(id) {
    return get(`assessments/${id}`, null);
}

/**
 * Post assessment answers by api
 *
 * @param {number} id - assessment id
 * @param {object} data - json data
 * @return Axios
 */
function submitAnswers(id, data) {
    return post(`assessments/answers`, {
        assessment_id: id,
        answers: data
    });
}

export const assessmentsService = {
    getAll,
    getById,
    submitAnswers
};