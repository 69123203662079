/**
 * Get alertMessages from localStorage
 * @return {object} - user data
 */
function getAll() {
    let data = localStorage.getItem("alertMessages")

    // if data exist parse json
    if (data) {
        data = JSON.parse(data)
    } else {
        data = {}
    }

    return data
}

/**
 * Add alertMessage to localStorage
 */
function add(message) {
    let data = getAll()
    const id = Date.now()

    data[id] = message
    localStorage.setItem("alertMessages", JSON.stringify(data))
}

/**
 * Remove alertMessage from localStorage
 */
function remove(id) {
    let data = getAll()
    delete data[id]

    localStorage.setItem("alertMessages", JSON.stringify(data))
}

export const alertMessages = {
    getAll,
    add,
    remove,
}